@import '../colors';

.project-search-wrapper {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;

  .selectable-option {
    margin-left: auto;
    margin-right: auto;
  }

  .selectable-option-label.checked {
    color: $white;
  }
}

.project-search {
  height: 400px;
  border: 5px $orange solid;
  border-radius: 20px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  display: flex;
  flex-direction: column;

  .search-bar-wrapper {
    margin: 12px;
  }
}

.project-search-results-wrapper {
  overflow: auto;

  &.loading {
    overflow: hidden;
  }
}

.project-search-results {
  text-align: left;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;

  .project-search-result {
    list-style: none;
    color: $white;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 16px;
    position: relative;
    display: block;
    cursor: pointer;
    margin-bottom: 1px;

    &:hover {
      background: #ffffff20;
    }

    &:active {
      background: #ffffff;
      color: $orange;
    }

    &-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-weight: 500;
      font-size: 18px;
    }

    &-in-catalog {
      font-size: 12px;
      color: $white;
      background: $light-gray;
      border-radius: 6px;
      padding: 2px 6px;
      display: inline;

      &.is-in {
        background: $green;
      }
    }

    &.loading {
      background: $light-gray;
      border-radius: 20px;
      height: 16px;
      padding: 0;
      margin-top: 2px;
      margin-bottom: 14px;
      margin-left: 16px;
      background: linear-gradient(45deg, $light-gray, $gray);
      animation: fading 2s ease-in-out infinite;
      background-size: 400% 400%;
      @for $i from 1 through 50 {
        &:nth-child(#{$i}) {
          animation-delay: 50ms * $i;
        }
      }
    }
  }
}

@keyframes fading {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}