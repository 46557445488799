@import '../colors';

.node-feature-outer-wrapper {
  .node-feature-dataset {
    color: white;
    text-align: left;
    margin-bottom: 4px;

    overflow: hidden;
    text-overflow: ellipsis;
  }

  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.node-feature-wrapper {
  display: flex;
  //position: absolute;

  .node-feature-input {
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    left: 0;
    //left: -49px;
    //top: 50%;
    //transform: translateY(-50%);

    .features-flow-handle {
      height: 30px;
      width: 27px;
      position: absolute;
      right: 0;
      left: 1px;
      opacity: 0;
      cursor: default;
    }

    .feature-input-handle {
      height: 44px;
      width: 44px;
      cursor: pointer;
    }

    .short-stem-handle {
      height: 44px;
      width: 44px;
    }

    .input-handle-circle {
      height: 44px;
      width: 44px;
      cursor: pointer;
    }
  }

  .plot-handle {
    width: 100px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    position: relative;
    margin-left: -1px;

    .features-flow-handle {
      position: absolute;
      left: -8px;
      height: 10px;
      width: 10px;
      opacity: 0;
    }

    .flat-flow-handle {
      height: 17px;
      .flat-edge-handle-1 {
        height: 19px;
        fill: $orange;
      }
    }

    .plot-handle-button {
      cursor: pointer;
    }
  }
}

.node {
  height: 30px;
  width: 150px;
  border: 3px solid $white;
  border-radius: 19px;
  padding: 2px;
  color: white;
  font-weight: 500;
  position: relative;

  &.plot {
    border: 5px solid $blue;
    background: $dark;
    position: relative;

    .plot-handle {
      position: absolute;
      height: 18px;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      left: -11px;

      .plot-flow-handle {
        position: absolute;
        left: -4px;
        opacity: 0;
      }

      .flat-flow-handle {
        height: 18px;

        .flat-edge-handle-1 {
          height: 19px;
          fill: $orange;
        }
      }
    }
  }

  &.factory {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: $dark;
    cursor: pointer;

    .pill-counter {
      color: $white;
      background: $orange;
      border-radius: 10px;
      border: none;
      height: 16px;
      line-height: 16px;
      width: 33px;
      font-size: 12px;
      font-weight: 600;
    }

    .node-close svg {
      height: 8px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }

    .node-text {
      margin-bottom: 2px;
    }

    &:active {
      background: $white;
      color: $orange;
    }
  }

  &.feature {
    height: 100%;
    width: 100%;
    background: $dark;
    border: 4px solid $orange;
    padding: 5px 5px 0 5px;
  }

  .node-close svg {
    height: 10px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
}

.node-dataset-path {
  color: white;
  text-align: left;
  padding-bottom: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &-wrapper {
    max-width: 200px;
  }
}

.react-flow__edge-path, .react-flow__connection-path {
  stroke-width: 4px !important;
  stroke: $orange !important;
}

.react-flow__edge {
  &.selected {
    .react-flow__edge-path, .react-flow__connection-path {
      stroke-width: 4px !important;
      stroke: #fff !important;
    }
  }

  &:hover {
    cursor: pointer !important;
  }
}

.plot-wrapper {
  //border: 1px solid #777;
  border-radius: 4px;
  background: #292929;
  color: white;
  padding: 6px;
  width: 100%;
  height: 100%;
  position: relative;
}

.plot-node-wrapper {
  .plot-settings-handle {
    position: absolute;
    display: flex;
    right: -76px;
    top: 12px;

    &.open {
      right: -262px;
    }
  }

  .plot-settings-button {
    height: 36px;
    width: 36px;
  }

  .plot-settings-stem {
    height: 36px;
    width: 36px;
    margin-right: -8px;

    &.flipped {
      transform: rotate(180deg);
      margin-right: -2px;
    }
  }
}

.plot-settings-modal {
  border: 4px $blue solid;
  border-radius: 10px;
  margin-right: -6px;
  margin-top: -12px;
  width: 150px;
  background: $dark;

  .selectable-option {
    &-label.checked {
      color: $blue;
    }
    .selectable-checkbox {
      border-color: $blue;

      &.checked {
        background: $blue;
      }
    }
  }

  .plot-settings-close svg {
    height: 10px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
}

.js-plotly-plot {
  //position: absolute;
}

.js-plotly-plot .plotly .main-svg {
  border-radius: 12px;
}

.modebar-btn--logo {
  display: none !important;
}

.choose-axis-wrapper {
  display: flex;
}