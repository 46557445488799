@import '../../colors';

.search-bar-wrapper {
  width: 100%;
  display: flex;
  margin: 10px;
}

.search-bar {
  background: none;
  border: none;
  outline: none;
  caret-color: $white;
  color: $white;
  box-sizing: border-box;
  width: 100%;
}