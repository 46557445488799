@import '../colors';

.start-from-project {

  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  height: 33%;
  width: 50%;

  @media (max-width: 748px) {
    width: 100%;
  }

  &.success {
    height: 66%;
    width: 50%;
  }

  &.add-project {
    width: 100%;
    max-width: 600px;
  }

  justify-content: space-evenly;

  .start-from-project-title {

    .start-from-a-project-text {
      margin-bottom: 16px;
    }

    svg {
      width: 160px;
    }
  }

  .ingesting-project {
    color: $white;
    font-size: 18px;

    &.error {
      color: darkred;
    }
  }

  .api-token-wrapper {
    position: relative;

    .api-token-save {
      color: $white;
      text-decoration: underline;
      cursor: pointer;
      font-size: 20px;
      font-weight: 500;
      position: absolute;
      top: -30px;
      left: auto;
      right: auto;
      width: 100%;
    }
  }

  .api-token-button {
    border: 5px solid $orange;
    border-radius: 14px;
    background: $dark;
    font-size: 24px;
    color: $orange;
    font-weight: 600;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 150px;
    cursor: pointer;
    margin: 8px auto auto;

    &.token-input {
      width: 250px;
      height: 38px;
      padding-top: 4px;
      padding-left: 8px;
      padding-right: 8px;
      cursor: default;
      margin-top: 0;

      input {
        background: none;
        border: none;
        outline: none;
        caret-color: $white;
        color: $white;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        resize: none;
      }
    }
  }

  .api-token-link-wrapper {
    margin-top: 4px;

    .api-token-link {
      color: $blue;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
    }
  }
}