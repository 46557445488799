@import '../../colors';

$checkbox-width: 18px;

.multi-select-search {
}

.multi-select-options {
  text-align: left;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  overflow: auto;
}

.choose-primary-feature {
  .selectable-checkbox {
    border-color: $blue;
    &.checked {
      background: $blue;
    }
  }
}