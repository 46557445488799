@import '../../colors.scss';

.selectable-option {
  list-style: none;
  color: $white;
  padding-bottom: 10px;
  padding-left: 10px;
  position: relative;
  display: flex;

  &-label {
    padding-left: 10px;
    cursor: pointer;
    text-overflow: ellipsis;
    margin-top: -3px;

    &.checked {
      color: $orange;
    }
  }

  .selectable-checkbox {
    height: 10px;
    width: 10px;
    border: 2px solid $orange;
    background: $white;
    cursor: pointer;

    &.checked {
      background: $orange;
    }
  }

  input[type="checkbox"] {
    display: none;
  }
}