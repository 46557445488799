.react-flow__controls {
  fill: #fff !important;
  box-shadow: none !important;
  display: flex !important;
  top: 20px !important;
  left: 20px !important;
  bottom: inherit !important;

  &-button {
    background: none !important;
    border: none !important;
  }
}

.react-flow__minimap-mask {
  opacity: 0.2 !important;
}