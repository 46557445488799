@import "../colors";

.Canvas {
  background: $dark;
  width: 100%;
  height: 100%;
  position: absolute;

  .canvas-buttons {
    position: absolute;
    top: 22px;
    left: 130px;
    z-index: 100;

    .search-dr {
      height: 20px;
      width: 20px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .starting-choices {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-evenly;
  }

  .starting-separator {
    border-left: 6px solid $blue;
    height: 70%;
    margin-top: auto;
    margin-bottom: auto;
  }

  h1 {
    text-align: center;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .drop-help-text {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .canvas-border {
    border: 2px dashed $blue;
    border-spacing: 1px;
    opacity: 0.5;
    pointer-events: none;

    border-radius: 10px;
    position: absolute;
    height: 98%;
    width: 98%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.dragging {
      opacity: 1;
    }
  }
}

.dropzone-container {
  position: fixed;
  z-index: 1000;

  &, .dropzone {
    height: 100%;
    width: 100%;
  }
}

.hide-if-small {
  @media (max-width: 748px) {
    display: none;
  }
}